var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          class: _vm.isHome ? "order-helper" : "update-vech",
          on: { click: _vm.showForm }
        },
        [
          _vm.isHome
            ? _c("i", { staticClass: "iconfont its_ba_faqirenwu1" })
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.isHome ? "工单助手" : "") + " ")
        ]
      ),
      _c(
        "el-dialog",
        {
          class: _vm.isHome
            ? "order-dialog home-order-dialog"
            : "order-dialog other-header",
          attrs: {
            title: "工单申请",
            width: "42%",
            "modal-append-to-body": true,
            visible: _vm.visible,
            modal: false
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            closed: _vm.closeDia
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "90px",
                model: _vm.ruleForm,
                rules: _vm.rules
              }
            },
            [
              _c(
                "div",
                { staticClass: "scoll-container" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "工单类型：", prop: "applicationTypeId" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择工单类型",
                            size: "small"
                          },
                          on: {
                            change: function(val) {
                              _vm.orderTypeList.forEach(function(type) {
                                if (type.dictCode === val) {
                                  _vm.ruleForm.applicationType = type.dictValue
                                }
                              })
                              if (val == 1) {
                                delete _vm.ruleForm.file
                              }
                              this$1.$refs.form.clearValidate()
                            }
                          },
                          model: {
                            value: _vm.ruleForm.applicationTypeId,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "applicationTypeId", $$v)
                            },
                            expression: "ruleForm.applicationTypeId"
                          }
                        },
                        _vm._l(_vm.orderTypeList, function(item) {
                          return _c("el-option", {
                            key: item.dictCode,
                            attrs: {
                              value: item.dictCode,
                              label: item.dictValue
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "车牌号：",
                                prop: "licensePlate",
                                rules:
                                  _vm.ruleForm.applicationTypeId == "4" ||
                                  _vm.ruleForm.applicationTypeId == "5"
                                    ? _vm.rules.licensePlate
                                    : [{ required: false }]
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  size: "small",
                                  disabled: _vm.vehDisabled,
                                  placeholder: "请填写车牌号"
                                },
                                on: { input: _vm.licensePlateINPUT },
                                model: {
                                  value: _vm.ruleForm.licensePlate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "licensePlate",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.licensePlate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "车牌颜色：",
                                prop: "licensePlateColorId"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.vehDisabled,
                                    placeholder: "车牌颜色"
                                  },
                                  on: { change: _vm.changeColor },
                                  model: {
                                    value: _vm.ruleForm.licensePlateColorId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "licensePlateColorId",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.licensePlateColorId"
                                  }
                                },
                                _vm._l(_vm.plateColorList, function(item) {
                                  return _c("el-option", {
                                    key: item.dictCode,
                                    attrs: {
                                      value: item.dictCode,
                                      label: item.dictValue
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "终端类型：",
                                prop: "vehicleTerminalTypeId",
                                rules:
                                  _vm.ruleForm.applicationTypeId == "5"
                                    ? _vm.rules.vehicleTerminalTypeId
                                    : [{ required: false }]
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "small",
                                    disabled: _vm.vehDisabled
                                  },
                                  on: {
                                    change: function(val) {
                                      _vm.deviceTypes.forEach(function(type) {
                                        if (type.dictCode === val) {
                                          _vm.ruleForm.vehicleTerminalType =
                                            type.dictValue
                                        }
                                      })
                                    }
                                  },
                                  model: {
                                    value: _vm.ruleForm.vehicleTerminalTypeId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "vehicleTerminalTypeId",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.vehicleTerminalTypeId"
                                  }
                                },
                                _vm._l(_vm.deviceTypes, function(item) {
                                  return _c("el-option", {
                                    key: item.dictCode,
                                    attrs: {
                                      value: item.dictCode,
                                      label: item.dictValue
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.ruleForm.applicationTypeId &&
                  (_vm.ruleForm.applicationTypeId == 2 ||
                    _vm.ruleForm.applicationTypeId == 3)
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "文件上传：", prop: "files" } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "uploader",
                              attrs: {
                                action: _vm.uploadUrl,
                                "auto-upload": false,
                                multiple: true,
                                accept:
                                  ".jpg,.jpeg,.png,.gif,.bmp,.doc,.docx,.xls,.xlsx,.pdf",
                                "on-change": _vm.getFile,
                                "on-remove": _vm.removeFile
                              }
                            },
                            [
                              _c("el-button", { attrs: { type: "text" } }, [
                                _vm._v("上传运政证明文件")
                              ])
                            ],
                            1
                          ),
                          !_vm.ruleForm.files
                            ? _c("span", [
                                _vm._v("支持文件类型:"),
                                _c("br"),
                                _vm._v(" 图片 .jpg/jpeg .png .gif .bmp, "),
                                _c("br"),
                                _vm._v(" 文档 .doc/docx .xls/.xlsx/.pdf ")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "联系人：",
                                prop: "contacts",
                                rules:
                                  _vm.ruleForm.applicationTypeId == "4"
                                    ? _vm.rules.contacts
                                    : [{ required: false }]
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  size: "small",
                                  placeholder: "联系人姓名"
                                },
                                model: {
                                  value: _vm.ruleForm.contacts,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "contacts", $$v)
                                  },
                                  expression: "ruleForm.contacts"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "电话：",
                                prop: "contactsPhone",
                                rules:
                                  _vm.ruleForm.applicationTypeId == "4"
                                    ? _vm.rules.contactsPhone
                                    : [{ required: false }]
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "联系人电话号码",
                                  size: "small"
                                },
                                model: {
                                  value: _vm.ruleForm.contactsPhone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "contactsPhone", $$v)
                                  },
                                  expression: "ruleForm.contactsPhone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "车辆位置：",
                        prop: "vehicleLocation",
                        rules:
                          _vm.ruleForm.applicationTypeId == "4"
                            ? _vm.rules.vehicleLocation
                            : [{ required: false }]
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "车辆位置", size: "small" },
                        model: {
                          value: _vm.ruleForm.vehicleLocation,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "vehicleLocation", $$v)
                          },
                          expression: "ruleForm.vehicleLocation"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.isHome
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "问题描述：",
                            prop: "problemDescription"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入",
                              autosize: "",
                              type: "textarea"
                            },
                            model: {
                              value: _vm.ruleForm.problemDescription,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "problemDescription",
                                  $$v
                                )
                              },
                              expression: "ruleForm.problemDescription"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isHome
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "图片描述：" } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "uploader",
                              attrs: {
                                action: _vm.uploadUrl,
                                "auto-upload": false,
                                multiple: true,
                                accept: ".jpg,.jpeg,.png,.gif,.bmp",
                                "on-change": _vm.getFile,
                                "on-remove": _vm.removeFile
                              }
                            },
                            [
                              _c("el-button", { attrs: { type: "text" } }, [
                                _vm._v("点击上传图片")
                              ])
                            ],
                            1
                          ),
                          !_vm.ruleForm.files
                            ? _c("span", [
                                _vm._v(
                                  "支持图片类型: .jpg/jpeg .png .gif .bmp "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "时间：", prop: "time" } },
                    [_vm._v(" " + _vm._s(_vm.time) + " ")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.submiting },
                      on: { click: _vm.submit }
                    },
                    [_vm._v("提交")]
                  ),
                  _c("el-button", { on: { click: _vm.closeDia } }, [
                    _vm._v("取消")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }